<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div class='wrap'>
    <mt-header title="申请单详情">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="invoice-apply-detail">
      <div class="item-wrap">
        <div class="item-line">
          <span>个体户姓名:</span>
          <span>{{detail.applyUserName}}</span>
        </div>
        <div class="item-line">
          <span>状态:</span>
          <span>{{detail.stateLabel}}</span>
        </div>
        <div class="item-line">
          <span>申请日期:</span>
          <span>{{detail.createDate}}</span>
        </div>
      </div>

      <div class="item-wrap">
        <div class="item-line">
          <span>购票方:</span>
          <span>{{detail.providerName}}</span>
        </div>
        <div class="item-line">
          <span>识别号:</span>
          <span>{{detail.sellerOrganCode}}</span>
        </div>
        <div class="item-line">
          <span>地址:</span>
          <span>{{detail.sellerAddress}}</span>
        </div>
        <div class="item-line">
          <span>电话:</span>
          <span>{{detail.sellerPhone}}</span>
        </div>
        <div class="item-line">
          <span>开户行:</span>
          <span>{{detail.sellerBankName}}</span>
        </div>
        <div class="item-line">
          <span>账号:</span>
          <span>{{detail.sellerBankCard}}</span>
        </div>
      </div>

      <div class="item-wrap">
        <div class="item-line">
          <span>销售方:</span>
          <span>{{detail.busiRegdName}}</span>
        </div>
        <div class="item-line">
          <span>识别号:</span>
          <span>{{detail.busiRegdCode}}</span>
        </div>
        <div class="item-line">
          <span>地址:</span>
          <span>{{detail.busiAddress}}</span>
        </div>
        <div class="item-line">
          <span>电话:</span>
          <span>{{detail.busiMobile}}</span>
        </div>
        <div class="item-line">
          <span>开户行:</span>
          <span>{{detail.busiBankName}}</span>
        </div>
        <div class="item-line">
          <span>账号:</span>
          <span>{{detail.busiBankCard}}</span>
        </div>
      </div>

      <div class="item-wrap">
        <div class="item-line">
          <span>开票金额:</span>
          <span>{{`¥ ${detail.amt || 0}`}}</span>
        </div>
        <div class="item-line">
          <span>手续费:</span>
          <span>{{`¥ ${detail.handlingFee || 0}`}}</span>
        </div>
      </div>

      <div class="item-wrap">
        <div class="check-line-goto" @click="handleIncome">
          <p>收入凭证</p>
        </div>
        <div class="check-line-goto" v-if="detail.state == 1&& !detail.invoiceImg" @click="handlePay">
          <p>查看付款说明</p>
        </div>
        <div class="check-line-goto" v-if="detail.invoiceImg" @click="handleInvoice">
          <p>发票信息</p>
        </div>
      </div>

      <div v-if="detail.state == 1 && !detail.invoiceImg" class="cancel-btn" @click="handleCancel">取消</div>
    </div>
  </div>
</template>

<script>
import { getinvoiceApplyDetail, getInvoiceApplyCancel } from '@api/individualBusiness';
import { Toast, MessageBox } from 'mint-ui';
import { formatDate } from "@utils/common";
export default {
  name: "invoice-apply-detail",
  props: ['id'],
  components: {
  },
  data() {
    return {
      detail: {}
    };
  },
  mounted() {
    getinvoiceApplyDetail(this.id).then(res => {
      if (res.code === 200) {
        res.data.createDate = formatDate(new Date(res.data.createDate), "yyyy-MM-dd HH:mm:ss")
        res.data.stateLabel = this.handleStata(Number(res.data.state));
        this.detail = res.data || {}
      } else {
        Toast(res.message);
      }
    })
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    handleStata(state) {
      switch(state) {
        case 1:
          return '待开票';
        case 2:
          return '开票成功';
        case 3:
          return '已取消';
        default: 
          return '';
      }
    },
    handleIncome() {
      this.$router.push(`/individual-business/invoice-apply-income/${encodeURIComponent(this.detail.incomeCertificateImg)}`);
    },
    handlePay() {
      const params = encodeURIComponent(JSON.stringify(this.detail));
      this.$router.push(`/individual-business/invoice-pay-explain/${params}`);
    },
    handleInvoice() {
      this.$router.push(`/individual-business/invoice-apply-invoice/${encodeURIComponent(this.detail.invoiceImg)}`);
    },
    handleCancel() {
      MessageBox.confirm('你确定要取消该申请单吗?').then(action => {
        if (action === 'confirm') {
          getInvoiceApplyCancel(this.id).then(res => {
            if (res.code === 200) {
              Toast('取消成功');
              this.detail.state = '3';
              this.detail.stateLabel = this.handleStata(3);
            } else {
              Toast(res.message);
            }
          })
        }
      }).catch(() => {
      });
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background-color: #f0f2f5;
}
.invoice-apply-detail {
  padding: 2.9333vw 5.3333vw 14.5333vw;
}
.item-wrap {
  width: 100%;
  padding: 8vw 5.3333vw;
  background-color: #ffffff;
  border-radius: 3.2vw;
  box-shadow: 0.4vw 0.4vw 1vw 0.43vw rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 3.2vw;
}
.item-line {
  font-size: 4vw;
  color: rgba(51,51,51,1);
  & + .item-line  {
    margin-top: 6vw;
  }
  display: flex;
  > span {
    display: inline-block;
    &:nth-of-type(1) {
      white-space: nowrap;
      // width: 33%;
      text-align: left;
      margin-right: 3%;
      color: rgba(51,51,51,0.7);
    }
    &:nth-of-type(2) {
      width: 64%;
      font-weight: 500;
    }
  }
}

.check-line-goto {
  width: 100%;
  font-size: 4vw;
  font-weight: 500;
  color: rgba(51,51,51,1);
  
  > p {
    border: 1px solid #dedede;
    height: 7.0667vw;
    line-height: 7.0667vw;
    border-radius: 3.3333vw;
    padding: 0 4vw;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

    &::after {
      content: '>'
    }
  }
}
.cancel-btn {
  font-size: 4.5333vw;
  font-weight: bold;
  color: rgba(255,255,255,1);
  height: 9.3333vw;
  line-height: 9.3333vw;
  width: 100%;
  background-image: linear-gradient(to bottom right, #2ba2f9, #47e2f8);
  text-align: center;
  border-radius: 4.5333vw;
  margin-top: 5.3333vw;
}
</style>